import React from "react";
import TablePro from "../../components/DataTable/TablePro";
import AdminLayout from "../../layouts/AdminLayout";
import CustomLoader from "../../components/CustomLoader";
import { Box, Grid, IconButton } from "@mui/material";
import { Delete, RemoveRedEye } from "@mui/icons-material";
import { useEffect } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import Swal from "sweetalert2";
import TransitionsModal from "../../components/Modal";

const ContactMessages = () => {
  const [open, setOpen] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  // Fetch data
  const fetchData = async () => {
    try {
      const res = await axios.get(`message`);
      setData(res.data.data || []); // Ensure data is an array
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteApi = async (url) => {
    try {
      await axios.delete(`${url}`);
    } catch (err) {
      toast.error("An error occurred while deleting data");
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteApi(`delete-message/${id}`);
          toast.success("Data deleted successfully!");
          fetchData();
        } catch (err) {
          toast.error("An error occurred while deleting data");
        }
      }
    });
  };

  const columns = [
    {
      name: "si",
      label: "SI",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "number",
      label: "Mobile number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = data[tableMeta.rowIndex]?.id;
          const view = data[tableMeta.rowIndex];
          return (
            <Box display="flex">
              <IconButton className="mr-3" onClick={() => handleDelete(id)}>
                <Delete className="text-red-500" />
              </IconButton>
              <IconButton
                className="mr-3"
                onClick={() => {
                  setModalData(view);
                  setOpen(true);
                }}
              >
                <RemoveRedEye className="text-blue-500" />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];


  const tableData = data.map((item, i) => ({
    si: i + 1,
    ...item,
  }));
  return (
    <AdminLayout title={"Messages"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TablePro columns={columns} data={tableData} title="Messages" />
          </Grid>
        </Grid>
      )}
      <TransitionsModal title={"View"} open={open} setOpen={setOpen}>
        <div>
          <h2>
            <strong>Name:</strong> {modalData?.name}
          </h2>
          <h2>
            <strong>Number:</strong> {modalData?.number}
          </h2>
          <h2>
            <strong>Email:</strong> {modalData?.email}
          </h2>
        </div>
        <strong>Message:</strong> <br />
        {modalData?.message}

        <br />
        {/* Add more fields as needed */}
      </TransitionsModal>
    </AdminLayout>
  );
};

export default ContactMessages;

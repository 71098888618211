import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const RouteAuthorize = ({ role }) => {
  const roleName = useSelector((state) => state.auth.user?.role?.name);

  // Ensure role is an array
  const rolesArray = Array.isArray(role) ? role : [role];
  // Check if the roleName exists and if it is included in the allowed roles
  if (!roleName || !rolesArray.includes(roleName)) {
    return <Navigate to="/authorize" replace />;
  }

  return <Outlet />;
};

export default RouteAuthorize;

import { Delete, Edit } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";
import CustomLoader from "../components/CustomLoader";
import TablePro from "../components/DataTable/TablePro";
import TransitionsModal from "../components/Modal";
import { deleteApi, getApi, postApi } from "../config/configAxios";
import AdminLayout from "../layouts/AdminLayout";

// Validation schema
const validationSchema = Yup.object({
  file: Yup.string().required("file is required"),
});

const TopSlider = () => {
  const [data, setData] = useState([]);
  const [storeData, setStoreData] = useState({
    file: "",
    logo: "",
    title: "",
  });
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch data
  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}top-slider`
      );
      setData(res.data.data || []); // Ensure data is an array
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "logo",
      label: "Logo",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <img
            src={`${process.env.REACT_APP_API_FILE_URL}${value}`}
            alt=""
            style={{ width: "100px", height: "auto" }}
          />
        ),
      },
    },
    {
      name: "file",
      label: "Image",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <img
            src={`${process.env.REACT_APP_API_FILE_URL}${value}`}
            alt=""
            style={{ width: "100px", height: "auto" }}
          />
        ),
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const id = data[tableMeta.rowIndex]?.id;
          return (
            <Box display="flex">
              <IconButton className="mr-3" onClick={() => handleDelete(id)}>
                <Delete className="text-red-500" />
              </IconButton>
              <IconButton className="mr-3" onClick={() => handleEdit(id)}>
                <Edit className="text-blue-500" />
              </IconButton>
            </Box>
          );
        },
      },
    },
  ];

  // Handle open modal
  const handleOpenStore = () => setOpenStore(true);

  // Handle file input change
  const handleFileChange = (e, setFieldValue) => {
    const { name, files } = e.target;
    setFieldValue(name, files[0]);
  };

  // Handle store
  const handleStore = async (values) => {
    setIsSubmitting(true);

    try {
      const formData = new FormData();

      formData.append("logo", values.logo); // Convert binary data to Blob
      formData.append("file", values.file);
      formData.append("title", values.title);
      await postApi(
        `${process.env.REACT_APP_API_BASE_URL}store-top-slider`,
        formData
      );
      setOpenStore(false);
      fetchData();
      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle delete
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteApi(`delete-top-slider/${id}`);
          toast.success("Data deleted successfully!");
          fetchData();
        } catch (err) {
          toast.error("An error occurred while deleting data");
        }
      }
    });
  };

  // Handle edit
  const handleEdit = async (id) => {
    try {
      const res = await getApi(`find-top-slider/${id}`);
      setEditData(res.data.data);
      setOpenEdit(true);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  // Handle update
  const handleUpdate = async (values) => {
    setIsSubmitting(true);

    const formData = new FormData();
    if (values.file) formData.append("file", values.file);
    if (values.logo) formData.append("logo", values.logo);
    formData.append("title", values.title);

    try {
      await postApi(
        `${process.env.REACT_APP_API_BASE_URL}update-top-slider/${editData.id}`,
        formData
      );
      setOpenEdit(false);
      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title={"Top slider"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              <button className="btn-green mr-3" onClick={handleOpenStore}>
                Add
              </button>
            </Box>
            <TablePro columns={columns} data={data} title="Top Slider Data" />
            <TransitionsModal
              title={"Add"}
              open={openStore}
              setOpen={setOpenStore}
            >
              <Formik
                initialValues={{
                  file: "",
                  logo: "",
                  title: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleStore}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                      <div className="md:col-span-2">
                        <label htmlFor="file" className="font-semibold text-sm">
                          Image
                        </label>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("file", file);
                          }}
                          className="form-control"
                          accept="image/jpeg,image/jpg,image/png"
                        />
                        <ErrorMessage
                          name="file"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label htmlFor="logo" className="font-semibold text-sm">
                          Logo
                        </label>
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            setFieldValue("logo", file);
                          }}
                          className="form-control"
                          accept="image/jpeg,image/jpg,image/png"
                        />
                        <ErrorMessage
                          name="logo"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label
                          htmlFor="title"
                          className="font-semibold text-sm"
                        >
                          Title
                        </label>
                        <Field
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Enter title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2 mt-4">
                        <button
                          type="submit"
                          className="btn-green"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </TransitionsModal>
            <TransitionsModal
              title={"Edit"}
              open={openEdit}
              setOpen={setOpenEdit}
            >
              <Formik
                initialValues={{
                  id: editData.id,
                  file: "",
                  logo: "",
                  title: editData.title || "",
                }}
                onSubmit={handleUpdate}
              >
                {({ setFieldValue, isSubmitting }) => (
                  <Form>
                    <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
                      <div className="md:col-span-2">
                        <label htmlFor="file" className="font-semibold text-sm">
                          Image
                        </label>
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                          className="form-control"
                          accept="image/jpeg,image/jpg,image/png"
                        />
                        <ErrorMessage
                          name="file"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label htmlFor="logo" className="font-semibold text-sm">
                          Logo
                        </label>
                        <input
                          type="file"
                          id="logo"
                          name="logo"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                          className="form-control"
                          accept="image/jpeg,image/jpg,image/png"
                        />
                        <ErrorMessage
                          name="logo"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2">
                        <label
                          htmlFor="title"
                          className="font-semibold text-sm"
                        >
                          Title
                        </label>
                        <Field
                          type="text"
                          id="title"
                          name="title"
                          placeholder="Enter title"
                          className="form-control"
                        />
                        <ErrorMessage
                          name="title"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="md:col-span-2 mt-4">
                        <button
                          type="submit"
                          className="btn-green"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </TransitionsModal>
          </Grid>
        </Grid>
      )}
    </AdminLayout>
  );
};

export default TopSlider;

import React from "react";

const Authorize = () => {
  return (
    <div className="flex flex-row  justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="font-semibold text-2xl">401</h1>
        <div>Unauthorize</div>
      </div>
    </div>
  );
};

export default Authorize;

import React from "react";
import { useSelector } from "react-redux";

export const useAuthorize = () => {
  const roleName = useSelector((state) => state.auth.user?.role?.name);

  const hasPermission = (roles) => {
    if (Array.isArray(roles)) {
      return roles.includes(roleName);
    }
    return roleName === roles;
  };

  return hasPermission;
};

import axios from "axios";
import store from "../redux/store";

// Set up base URL and default headers for all Axios requests
const configureAxios = () => {
  // Dynamically get the token from the Redux store
  const state = store.getState();
  const token = state.auth.token;
  if (token) {
    // axios.defaults.headers.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  // Set the base URL for all requests
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  // Default headers for JSON requests
  axios.defaults.headers.post["Content-Type"] = "application/json";
  axios.defaults.headers.post["Accept"] = "application/json";

  // You can conditionally set 'multipart/form-data' for file uploads
   axios.defaults.headers.post["Content-Type"] = "multipart/form-data"; // For file uploads
};

// Handle API requests and responses
const handleRequest = async (requestFunction) => {
  try {
    const response = await requestFunction();
    return response;
  } catch (error) {
    console.error(
      "API Request Error:",
      error.response ? error.response.data : error.message
    );
    throw error; // Propagate the error to be handled by the caller
  }
};

// GET request
export const getApi = async (url) => {
  return handleRequest(() => axios.get(url));
};

// GET request by ID
export const getApiById = async (url, id) => {
  const urlWithUserId = `${url}?id=${id}`;
  return handleRequest(() => axios.get(urlWithUserId));
};

// POST request
export const postApi = async (url, data) => {
  return handleRequest(() => axios.post(url, data));
};

// PUT request (fixed to use axios.put instead of axios.post)
export const putApi = async (url, data) => {
  return handleRequest(() => axios.put(url, data));
};

// DELETE request
export const deleteApi = async (url) => {
  return handleRequest(() => axios.delete(url));
};

// Call configureAxios once at the beginning of your app
configureAxios();

import React from "react";
import { SubMenu } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";

export const SubMenuCustom = ({ icon, label, url, children }) => {
  const location = useLocation();

  // Check if the current path is in the array
  const actuveUrl = url.includes(location.pathname);

  // Conditionally set the className based on the result
  const className = actuveUrl ? "bg-blue-50" : "";
  return (
    <SubMenu open={actuveUrl} label={label} icon={icon} className={className}>
      {children}
    </SubMenu> 
  );
};

import React, { useState } from "react";
import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import { ArrowDropDownCircle, Lock, Logout } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutUser, clearMessage } from "../../redux/features/AuthSlice";

const AvatarMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap(); 
    } catch (error) {
      console.error("Error logging out:", error);
      // Optionally, display a user-friendly message here
    } finally {// Unwrap to catch rejected promises
      localStorage.clear(); // Clear local storage on successful logout
      dispatch(clearMessage()); // Clear messages after logout
      navigate("/login"); // Navigate to login page
      handleMenuClose(); // Close the menu
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={handleMenuOpen}
          display={"flex"}
          alignItems={"center"}
          border={"1px solid #f1f1f1"}
          px={"17px"}
          py={"5px"}
          borderRadius={"50px"}
        >
          <ArrowDropDownCircle />
          <Avatar
            alt="Remy Sharp"
            src={""}
            sx={{ width: 30, height: 30, marginLeft: "10px" }}
          />
        </Box>
      </Box>
      <Menu
        id="account-menu"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link to="/change-password">
          <MenuItem sx={{ minWidth: "250px" }}>
            <Lock className="pr-2" /> Change Password
          </MenuItem>
        </Link>
        <MenuItem sx={{ minWidth: "250px" }} onClick={handleLogout}>
          <Logout className="pr-2" /> Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMenu;

import React, { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { BeatLoader } from "react-spinners";
import axios from "axios";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import CustomLoader from "../components/CustomLoader";
import TransitionsModal from "../components/Modal";
import AdminLayout from "../layouts/AdminLayout";
import { deleteApi, getApi, postApi } from "../config/configAxios";

const PhoneDirectory = () => {
  const [data, setData] = useState([]);
  const [policeUnit, setPoliceUnit] = useState([]);
  const [policeSubUnit, setPoliceSubUnit] = useState([]);
  const [storeData, setStoreData] = useState({});
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const [officers, units, subUnits] = await Promise.all([
        getApi("phone-directory"),
        getApi("/police-unit"),
      ]);
      setData(officers.data.data);
      setPoliceUnit(units.data.data || []);
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  const fetchSubUnit = async (find_id) => {
    try {
      const subUnits = await getApi("/police-sub-unit?unit_id=" + find_id);
      setPoliceSubUnit(subUnits.data.data || []);
    } catch (error) {
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (storeData?.police_unit_id) {
      fetchSubUnit(storeData?.police_unit_id);
    }
  }, [storeData?.police_unit_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "police_unit_id") {
      setStoreData((prevData) => ({ ...prevData, police_sub_unit_id: "" }));
    }

    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setStoreData((prevData) => ({ ...prevData, file }));
  };

  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    Object.keys(storeData).forEach((key) => {
      formData.append(key, storeData[key]);
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-phone-directory`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      toast.success("Data created successfully!");
      setOpenStore(false);
      setStoreData({});
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to create data");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Confirm delete",
        text: "Are you sure you want to delete this?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        await deleteApi(`delete-phone-directory/${id}`);
        toast.success("Data deleted successfully!");
        await fetchData();
      } else {
        Swal.fire("Cancelled", "The user was not deleted.", "info");
      }
    } catch (error) {
      toast.error("An error occurred while deleting the user.");
    }
  };

  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`find-phone-directory/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data);
    } catch (error) {
      toast.error("Failed to fetch officer data");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    Object.keys(editData).forEach((key) => {
      formData.append(key, editData[key]);
    });

    try {
      await postApi(
        `${process.env.REACT_APP_API_BASE_URL}update-phone-directory/${editData.id}`,
        formData
      );
      toast.success("Data updated successfully!");
      setOpenEdit(false);
      setStoreData({});
      await fetchData();
    } catch (error) {
      toast.error(error?.response?.data?.error || "Failed to update data");
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log(editData);

  return (
    <AdminLayout title="Officer">
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">Officer List</h1>
            <button
              className="btn-indigo"
              onClick={() => {
                setStoreData({});
                setOpenStore(true);
              }}
            >
              Add Officer
            </button>
          </div>
          <div className="table-wrap">
            <table className="table-main shadow-md">
              <thead className="table-h">
                <tr>
                  <th className="table-h">SN</th>
                  <th className="table-h">Name</th>
                  <th className="table-h">Photo</th>
                  <th className="table-h">Designation</th>
                  <th className="table-h">Index</th>
                  <th className="table-h">Action</th>
                </tr>
              </thead>
              <tbody className="table-b">
                {data.map((item, index) => (
                  <tr key={item.id}>
                    <td className="table-b">{index + 1}</td>
                    <td className="table-b">{item.name}</td>
                    <td className="table-b">
                      <img
                        className="h-10 rounded-lg"
                        src={`${process.env.REACT_APP_API_FILE_URL}${item.file}`}
                        alt="Officer"
                      />
                    </td>
                    <td className="table-b">{item.designation}</td>
                    <td className="table-b">{item.index}</td>
                    <td className="table-b">
                      <Box display="flex">
                        <IconButton
                          className="mr-3"
                          onClick={() => handleDelete(item.id)}
                        >
                          <Delete className="text-red-500" />
                        </IconButton>
                        <IconButton
                          className="mr-3"
                          onClick={() => handleEdit(item.id)}
                        >
                          <Icon icon="bxs:edit" className="text-blue-500" />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Add Officer Modal */}
      <TransitionsModal
        title="Add Officer"
        open={openStore}
        setOpen={setOpenStore}
      >
        <form onSubmit={handleStore}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="md:col-span-1">
              <label htmlFor="police_unit_id" className="font-semibold text-sm">
                Select Division
              </label>
              <select
                id="police_unit_id"
                name="police_unit_id"
                value={storeData.police_unit_id || ""}
                onChange={(e) => {
                  fetchSubUnit(e.target.value);
                  handleInputChange(e);
                }}
                className="form-control max-h-16 overflow-y-scroll"
                required
              >
                <option value="">Select Division</option>
                {policeUnit.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="md:col-span-1">
              <label
                htmlFor="police_sub_unit_id"
                className="font-semibold text-sm"
              >
                Select District
              </label>
              <select
                id="police_sub_unit_id"
                name="police_sub_unit_id"
                value={storeData.police_sub_unit_id || ""}
                onChange={handleInputChange}
                className="form-control max-h-16 overflow-y-scroll"
                required
              >
                <option value="">Select District</option>
                {policeSubUnit.map((subUnit) => (
                  <option key={subUnit.id} value={subUnit.id}>
                    {subUnit.name}
                  </option>
                ))}
              </select>
            </div>

            {[
              "name",
              "designation",
              "index",
              "mobile",
              "email",
              "phone",
              "joining_date",
            ].map((field) => (
              <div key={field}>
                <label
                  htmlFor={field}
                  className="font-semibold text-sm capitalize"
                >
                  {field
                    .replace(/_/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </label>
                {field === "joining_date" ? (
                  <input
                    type="date"
                    id={field}
                    name={field}
                    value={storeData[field] || ""}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                ) : (
                  <input
                    type={field === "email" ? "email" : "text"}
                    id={field}
                    name={field}
                    value={storeData[field] || ""}
                    onChange={handleInputChange}
                    placeholder={field}
                    className="form-control"
                    required
                  />
                )}
              </div>
            ))}
            <div>
              <label htmlFor="file" className="font-semibold text-sm">
                Photo
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="Charger votre fichier"
                className="form-control"
                required
              />
            </div>
            {storeData?.file && typeof storeData?.file !== "string" && (
              <div>
                <label htmlFor="file" className="font-semibold text-sm">
                  New Photo
                </label>
                <img
                  className="h-20 border border-dashed rounded-md p-3"
                  src={URL.createObjectURL(storeData?.file)}
                  alt="Preview"
                />
              </div>
            )}
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-indigo"
              >
                {isSubmitting ? <BeatLoader color="#fff" size={10} /> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>

      {/* Edit Officer Modal */}
      <TransitionsModal
        title="Edit Officer"
        open={openEdit}
        setOpen={setOpenEdit}
      >
        <form onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div>
              <label htmlFor="police_unit_id" className="font-semibold text-sm">
                Select Division
              </label>
              <select
                id="police_unit_id"
                name="police_unit_id"
                value={editData.police_unit_id || ""}
                onChange={(e) => {
                  fetchSubUnit(e.target.value);
                  setEditData({ ...editData, police_unit_id: e.target.value });
                }}
                className="form-control max-h-16 overflow-y-scroll"
                required
              >
                <option value="">Select Division</option>
                {policeUnit.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="police_sub_unit_id"
                className="font-semibold text-sm"
              >
                Select District
              </label>
              <select
                id="police_sub_unit_id"
                name="police_sub_unit_id"
                value={editData.police_sub_unit_id || ""}
                onChange={(e) =>
                  setEditData({
                    ...editData,
                    police_sub_unit_id: e.target.value,
                  })
                }
                className="form-control max-h-16 overflow-y-scroll"
                required
              >
                <option value="">Select District</option>
                {policeSubUnit.map((subUnit) => (
                  <option key={subUnit.id} value={subUnit.id}>
                    {subUnit.name}
                  </option>
                ))}
              </select>
            </div>

            {[
              "name",
              "designation",
              "index",
              "mobile",
              "email",
              "phone",
              "joining_date",
            ].map((field) => (
              <div key={field}>
                <label
                  htmlFor={field}
                  className="font-semibold text-sm capitalize"
                >
                  {field
                    .replace(/_/g, " ")
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </label>
                {field === "joining_date" ? (
                  <input
                    type="date"
                    id={field}
                    name={field}
                    value={editData[field] || ""}
                    onChange={(e) =>
                      setEditData({ ...editData, [field]: e.target.value })
                    }
                    className="form-control"
                  />
                ) : (
                  <input
                    type={field === "email" ? "email" : "text"}
                    id={field}
                    name={field}
                    value={editData[field] || ""}
                    onChange={(e) =>
                      setEditData({ ...editData, [field]: e.target.value })
                    }
                    placeholder={field}
                    className="form-control"
                    required
                  />
                )}
              </div>
            ))}
            <div className="grid grid-cols-2 gap-6">
              <div className="mr-3 ">
                <label htmlFor="file" className="font-semibold text-sm">
                  Old Photo
                </label>
                <img
                  className="h-20 border border-dashed rounded-md p-3"
                  src={`${process.env.REACT_APP_API_FILE_URL}${editData?.file}`}
                  alt=""
                />
              </div>
              <div>
                {storeData?.file && typeof storeData?.file !== "string" && (
                  <>
                    <label htmlFor="file" className="font-semibold text-sm">
                      New Photo
                    </label>
                    <img
                      className="h-20 border border-dashed rounded-md p-3"
                      src={URL.createObjectURL(storeData?.file)}
                      alt="Preview"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-indigo"
              >
                {isSubmitting ? (
                  <BeatLoader color="#fff" size={10} />
                ) : (
                  "Update"
                )}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default PhoneDirectory;

import React, { useEffect, useState } from "react";
import AdminLayout from "../layouts/AdminLayout";
import CustomLoader from "../components/CustomLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const LogoWebPortal = () => {
  const [data, setData] = useState({});
  const [oldFile, setOldFile] = useState({});
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}logo-web-portal`
      );
      setData(res.data.data);
      setOldFile(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setData((prevData) => ({ ...prevData, logo: file }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("web_portal_title", data.web_portal_title);
    formData.append("link", data.link);
    formData.append("logo", data.logo);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}update-logo-web-portal/${data.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AdminLayout title={"Logo and web portal"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div>
              <label htmlFor="title" className="font-semibold text-sm">
                Web portal title
              </label>
              <input
                type="text"
                id="title"
                name="web_portal_title"
                value={data?.web_portal_title}
                onChange={handleInputChange}
                placeholder="web portal title"
                className="form-control"
                required
              />
            </div>
            <div>
              <label htmlFor="link" className="font-semibold text-sm">
                Link
              </label>
              <input
                type="text"
                id="link"
                name="link"
                value={data?.link}
                onChange={handleInputChange}
                placeholder="Link"
                className="form-control"
                required
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Logo
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                placeholder="Charger votre fichier"
                className="form-control"
              />
            </div>
            <div className="flex gap-2">
              <div>
                <label htmlFor="file" className="font-semibold text-sm">
                  Old logo
                </label>
                <img
                  className="h-20 border border-dashed rounded-md p-3"
                  src={`${process.env.REACT_APP_API_FILE_URL}${oldFile?.logo}`}
                  alt=""
                />
              </div>
              <div>
                {data?.logo && typeof data?.logo !== "string" && (
                  <>
                    <label htmlFor="file" className="font-semibold text-sm">
                      New logo
                    </label>
                    <img
                      className="h-20 border border-dashed rounded-md p-3"
                      src={URL.createObjectURL(data?.logo)}
                      alt="Preview"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="md:col-span-2">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
                data-ripple-light="true"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      )}
    </AdminLayout>
  );
};

export default LogoWebPortal;

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthorize } from "./hooks/useAuthorize";
import Authorize from "./pages/401";
import NotFound from "./pages/404";
import ActivitySlider from "./pages/ActivitySlider";
import Category from "./pages/Category";
import CompanyInfo from "./pages/CompanyInfo";
import Dashboard from "./pages/Dashboard";
import FooterButton from "./pages/FooterButton";
import FooterContent from "./pages/FooterContent";
import FooterHeading from "./pages/FooterHeading";
import GalleryCategory from "./pages/GalleryCategory";
import LogoWebPortal from "./pages/LogoWebPortal";
import NoticeBoard from "./pages/NoticeBoard";
import Officer from "./pages/Officer";
import PhoneDirectory from "./pages/PhoneDirectory";
import PhotoGallery from "./pages/PhotoGallery";
import PoliceSubUnit from "./pages/PoliceSubUnit";
import PoliceUnit from "./pages/PoliceUnit";
import RecentActivity from "./pages/RecentActivity";
import RightbarOther from "./pages/RightbarOther";
import SidebarCategory from "./pages/SidebarCategory";
import SidebarSubCategory from "./pages/SidebarSubCategory";
import SubCategory from "./pages/SubCategory";
import TopBanner from "./pages/TopBanner";
import TopSlider from "./pages/TopSlider";
import VideoGallery from "./pages/VideoGallery";
import Administrator from "./pages/administrator/Administrator";
import AdministratorPost from "./pages/administrator/AdministratorPost";
import ChangePassword from "./pages/auth/ChangePassword";
import LoginScreen from "./pages/auth/Login";
import ContactInfo from "./pages/contact/ContactInfo";
import ContactMessages from "./pages/contact/ContactMessages";
import MenuItem from "./pages/menu/MenuItem";
import Post from "./pages/menu/Post";
import MenuPost from "./pages/table_menu/MenuPost";
import TableMenu from "./pages/table_menu/TableMenu";
import ProtectedRouteLogin from "./utils/ProtectedRouteLogin";
import RouteAuthorize from "./utils/RouteAuthorize";
import ProtectedRoute from "./utils/RouteProtect";
import Users from "./pages/Users";

function App() {
  const hasPermission = useAuthorize();
  return (
    <>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route element={<RouteAuthorize role={["Admin"]} />}>
              <Route path="/users" element={<Users />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/logo-web-portal" element={<LogoWebPortal />} />
              <Route path="/top-slider" element={<TopSlider />} />
              <Route path="/officer" element={<Officer />} />
              <Route path="/rightbar-other" element={<RightbarOther />} />
              <Route path="/top-banner" element={<TopBanner />} />
              <Route path="/activity-slider" element={<ActivitySlider />} />
              <Route path="/recent-activity" element={<RecentActivity />} />
              <Route path="/sidebar-category" element={<SidebarCategory />} />
              <Route
                path="/sidebar-sub-category"
                element={<SidebarSubCategory />}
              />

              {/* footer  */}
              <Route path="/company-info" element={<CompanyInfo />} />
              <Route path="/footer-button" element={<FooterButton />} />
              <Route path="/footer-heading" element={<FooterHeading />} />
              <Route path="/footer-content" element={<FooterContent />} />

              {/* work section  */}
              <Route path="/video-gallery" element={<VideoGallery />} />
              <Route path="/photo-gallery" element={<PhotoGallery />} />
              <Route path="/gallery-category" element={<GalleryCategory />} />
              <Route path="/category" element={<Category />} />
              <Route path="/sub-category" element={<SubCategory />} />
              <Route path="/notice-board" element={<NoticeBoard />} />

              {/* manu  */}
              <Route path="/menu-item" element={<MenuItem />} />
              <Route path="/post" element={<Post />} />

              {/* table manu  */}
              <Route path="/table-menu" element={<TableMenu />} />
              <Route path="/table-menu-post/:id" element={<MenuPost />} />
              {/* bit police */}
              {/* <Route path="/bit-thanas" element={<ThanaList />} />
              <Route path="/bit-officers" element={<BitOfficer />} /> */}

              {/* phone directory  */}
              <Route path="/division" element={<PoliceUnit />} />
              <Route path="/district" element={<PoliceSubUnit />} />
              <Route path="/phone-directory" element={<PhoneDirectory />} />

              {/* Administrator */}
              <Route path="/administrator" element={<Administrator />} />
              <Route
                path="/administrator-post"
                element={<AdministratorPost />}
              />
            </Route>
            <Route path="/" element={<Dashboard />} />
            {/* <Route element={<RouteAuthorize role={["Admin", "Bit Officer"]} />}>
              {hasPermission("Bit Officer") ? (
                <Route path="/" element={<BitNews />} />
              ) : (
                <>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/bit-news" element={<BitNews />} />
                </>
              )}
            </Route> */}
            {/* contact  */}
            <Route path="/contact-info" element={<ContactInfo />} />
            <Route path="/contact-messages" element={<ContactMessages />} />
          </Route>
          <Route element={<ProtectedRouteLogin />}>
            <Route path="/login" element={<LoginScreen />} />
          </Route>
          <Route path="/authorize" element={<Authorize />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Zoom}
        style={{
          width: "300px",
          fontSize: "14px",
        }}
      />
    </>
  );
}

export default App;

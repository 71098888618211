import { Delete, Edit } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import CustomLoader from "../../components/CustomLoader";
import TablePro from "../../components/DataTable/TablePro";
import TransitionsModal from "../../components/Modal";
import { deleteApi, getApi, postApi } from "../../config/configAxios";
import AdminLayout from "../../layouts/AdminLayout";

const AdministratorPost = () => {
  const [data, setData] = useState([]);
  const [storeData, setStoreData] = useState({});
  const [editData, setEditData] = useState({});
  const [selectMenu, setSelectMenu] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openStore, setOpenStore] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState("");

  const fetchData = async () => {
    setLoading(true); // Start loading
    try {
      const res = await getApi(`administrator-post`);
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const fetchSelectMenu = async () => {
    try {
      const res = await getApi(`administrator-client`);
      setSelectMenu(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetchData();
    fetchSelectMenu();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleStore = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    Object.entries(storeData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);

    try {
      await axios.post(`administrator-post`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setOpenStore(false);
      setStoreData({});
      fetchData();
      toast.success("Data created successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Creation failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Confirm delete",
      text: "Are you sure you want to delete this?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        await deleteApi(`administrator-post/${id}`);
        toast.success("Data deleted successfully!");
        fetchData();
      } catch (err) {
        toast.error(err?.response?.data?.error || "Deletion failed");
      }
    }
  };

  const handleEdit = async (id) => {
    setOpenEdit(true);
    try {
      const res = await getApi(`administrator-post/${id}`);
      setEditData(res.data.data);
      setStoreData(res.data.data); // Populate form for editing
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("_method", "PUT");
    Object.entries(storeData).forEach(([key, value]) => {
      formData.append(key, value);
    });
    if (file) {
      formData.append("file", file);
    }

    try {
      await postApi(`administrator-post/${editData.id}`, formData);
      setOpenEdit(false);
      setStoreData({});
      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "Update failed");
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    { name: "sn", label: "SN", options: { filter: false, sort: true } },
    {
      name: "file",
      label: "Photo",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <img
            className="h-10 rounded-lg"
            src={`${process.env.REACT_APP_API_FILE_URL}${value}`}
            alt=""
          />
        ),
      },
    },
    {
      name: "pname",
      label: "Parent Menu",
      options: { filter: true, sort: true },
    },
    { name: "name", label: "Name", options: { filter: true, sort: true } },
    { name: "mobile", label: "Mobile", options: { filter: true, sort: true } },
    {
      name: "designation",
      label: "Designation",
      options: { filter: true, sort: true },
    },
    {
      name: "id",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <Box display="flex">
            <IconButton onClick={() => handleDelete(value)}>
              <Delete className="text-red-500" />
            </IconButton>
            <IconButton onClick={() => handleEdit(value)}>
              <Edit className="text-blue-500" />
            </IconButton>
          </Box>
        ),
      },
    },
  ];

  return (
    <AdminLayout title={"Administrator Post"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <div>
          <div className="flex justify-between p-3">
            <h1 className="text-gray-700 font-medium">
              Administrator Post list
            </h1>
            <button className="btn-indigo" onClick={() => setOpenStore(true)}>
              Add new
            </button>
          </div>
          <div className="table-wrap">
            <TablePro
              title={"Post List"}
              data={data.map((item, index) => ({
                sn: index + 1,
                pname: item?.parent_menu?.name,
                ...item,
              }))}
              columns={columns}
            />
          </div>
        </div>
      )}

      {/* Modal for Add */}
      <TransitionsModal title={"Add"} open={openStore} setOpen={setOpenStore}>
        <form onSubmit={handleStore}>
          <div className="grid md:grid-cols-4 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="menu_item_id" className="font-semibold text-sm">
                Select category
              </label>
              <select
                id="menu_item_id"
                name="administrator_id"
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="">Select</option>
                {selectMenu.map((item) => (
                  <React.Fragment key={item.id}>
                    <option value={item.id}>{item.name}</option>
                    {item.submenus?.map((submenu) => (
                      <option key={submenu.id} value={submenu.id}>
                        {">"} {submenu.name}
                      </option>
                    ))}
                  </React.Fragment>
                ))}
              </select>
            </div>

            {/* Other input fields */}
            <div className="md:col-span-2">
              <label htmlFor="name" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleInputChange}
                placeholder="name"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Photo
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="designation" className="font-semibold text-sm">
                Designation
              </label>
              <input
                type="text"
                id="designation"
                name="designation"
                onChange={handleInputChange}
                placeholder="designation"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="start" className="font-semibold text-sm">
                Start Date
              </label>
              <input
                type="date"
                id="start"
                name="start"
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="end" className="font-semibold text-sm">
                End Date
              </label>
              <input
                type="date"
                id="end"
                name="end"
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="phone" className="font-semibold text-sm">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                onChange={handleInputChange}
                placeholder="phone"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="mobile" className="font-semibold text-sm">
                Mobile
              </label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                onChange={handleInputChange}
                placeholder="mobile"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="email" className="font-semibold text-sm">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleInputChange}
                placeholder="email"
                className="form-control"
              />
            </div>
            <div className="md:col-span-4">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>

      {/* Modal for Edit */}
      {/* Modal for Edit */}
      <TransitionsModal title={"Edit"} open={openEdit} setOpen={setOpenEdit}>
        <form onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-4 grid-cols-1 gap-6">
            <div className="md:col-span-2">
              <label htmlFor="menu_item_id" className="font-semibold text-sm">
                Select menu
              </label>
              <select
                id="menu_item_id"
                name="administrator_id"
                onChange={handleInputChange}
                className="form-control"
                value={storeData?.administrator_id || ""}
              >
                <option value="">Select</option>
                {selectMenu.map((item) => (
                  <React.Fragment key={item.id}>
                    <option value={item.id}>{item.name}</option>
                    {item.submenus?.map((submenu) => (
                      <option key={submenu.id} value={submenu.id}>
                        {">"} {submenu.name}
                      </option>
                    ))}
                  </React.Fragment>
                ))}
              </select>
            </div>

            <div className="md:col-span-2">
              <label htmlFor="name" className="font-semibold text-sm">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={storeData?.name || ""}
                onChange={handleInputChange}
                placeholder="name"
                className="form-control"
                required
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="file" className="font-semibold text-sm">
                Photo
              </label>
              <input
                type="file"
                id="file"
                name="file"
                onChange={handleFileChange}
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="designation" className="font-semibold text-sm">
                Designation
              </label>
              <input
                type="text"
                id="designation"
                name="designation"
                value={storeData?.designation || ""}
                onChange={handleInputChange}
                placeholder="designation"
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="start" className="font-semibold text-sm">
                Start Date
              </label>
              <input
                type="date"
                id="start"
                name="start"
                value={storeData?.start || ""}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="end" className="font-semibold text-sm">
                End Date
              </label>
              <input
                type="date"
                id="end"
                name="end"
                value={storeData?.end || ""}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <label htmlFor="phone" className="font-semibold text-sm">
                Phone
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={storeData?.phone || ""}
                onChange={handleInputChange}
                placeholder="phone"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="mobile" className="font-semibold text-sm">
                Mobile
              </label>
              <input
                type="text"
                id="mobile"
                name="mobile"
                value={storeData?.mobile || ""}
                onChange={handleInputChange}
                placeholder="mobile"
                className="form-control"
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="email" className="font-semibold text-sm">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={storeData?.email || ""}
                onChange={handleInputChange}
                placeholder="email"
                className="form-control"
              />
            </div>

            <div className="md:col-span-4">
              <button
                disabled={isSubmitting}
                type="submit"
                className="btn-green w-full"
              >
                {isSubmitting ? <BeatLoader color="#fff" /> : "Update"}
              </button>
            </div>
          </div>
        </form>
      </TransitionsModal>
    </AdminLayout>
  );
};

export default AdministratorPost;

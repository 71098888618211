import React, { useEffect, useState } from "react";
import AdminLayout from "../../layouts/AdminLayout";
import CustomLoader from "../../components/CustomLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const ContactInfo = () => {
  const [data, setData] = useState({
    id: "",
    address: "",
    number: "",
    email: "",
    map_url: "",
  });
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}message-data`
      );
      setData(res.data.data);
    } catch (err) {
      toast.error(err?.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}store-message-data`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      fetchData();
      toast.success("Data updated successfully!");
    } catch (err) {
      toast.error(err?.response?.data?.error || "update failed");
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <AdminLayout title={"Contact Information"}>
      {loading ? (
        <CustomLoader />
      ) : (
        <form className="" onSubmit={handleUpdate}>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div>
              <label htmlFor="email" className="font-semibold text-sm">
                Email
              </label>
              <input
                type="text"
                id="email"
                name="email"
                value={data?.email}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="number" className="font-semibold text-sm">
                Mobile Number
              </label>
              <input
                type="text"
                id="number"
                name="number"
                value={data?.number}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="address" className="font-semibold text-sm">
                Address
              </label>
              <input
                type="text"
                id="address"
                name="address"
                value={data?.address}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
            <div>
              <label htmlFor="map_url" className="font-semibold text-sm">
                Map URL
              </label>
              <input
                type="text"
                id="map_url"
                name="map_url"
                value={data?.map_url}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>

            <div className="md:col-span-2">
              <div className="text-center">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="btn-green px-16"
                  data-ripple-light="true"
                >
                  {isSubmitting ? <BeatLoader color="#fff" /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </AdminLayout>
  );
};

export default ContactInfo;

import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import logImg from "../../assets/login.svg";
import { clearMessage, loginUser } from "../../redux/features/AuthSlice";

import { BeatLoader } from "react-spinners";
import ForgotPassword from "./ForgotPassword";

const LoginScreen = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { error, success } = useSelector((state) => state?.auth);
  const [openEdit, setOpenEdit] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const userSchema = Yup.object({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must have at least 6 characters"),
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: userSchema,

    onSubmit: async (values, action) => {
      try {
        setSubmitting(true);

        const loginData = { email: values.email, password: values.password };
        // Dispatch the loginUser action
        await dispatch(loginUser(loginData));

        setSubmitting(false);
      } catch {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearMessage());
    }
    if (success) {
      toast.success(success);
      navigate("/"); // Redirect after success
    }
  }, [error, success, dispatch, navigate]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor={"#e7e7e7"}
    >
      <Box p={4} bgcolor={"#fafcfe"} borderRadius={"5px"}>
        <div className="card">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
              >
                <Box p={1}>
                  <Link to={"/"}>
                    <img
                      src={logImg}
                      alt="Airbnb Logo"
                      style={{
                        maxWidth: "250px",
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                p={4}
                textAlign={"center"}
                xs={{
                  width: "100%",
                  borderLeft: {
                    md: "1px solid #000",
                    sm: "none",
                  },
                }}
                bgcolor={"#fafcfe"}
              >
                <Link to={"/"}>
                  <img
                    src={
                      "https://images.vexels.com/media/users/3/142789/isolated/preview/2bfb04ad814c4995f0c537c68db5cd0b-multicolor-swirls-circle-logo.png"
                    }
                    alt="Logo"
                    style={{
                      height: "100px",
                      cursor: "pointer",
                      margin: "0 auto",
                    }}
                  />
                </Link>
                <h1 className="text-2xl font-bold  text-gray-800 mb-6">
                  Sign In
                </h1>
                <div style={{ textAlign: "left", width: "100%" }}>
                  <h2 className="text-xl font-semibold text-gray-800">
                    Welcome! 👋🏻
                  </h2>
                  <p className="text-gray-600">
                    Please sign in to your account and start the adventure.
                  </p>
                </div>
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                  <Box pt={2}>
                    <TextField
                      type="email"
                      label="Email"
                      fullWidth
                      name="email"
                      error={!!errors.email && touched.email}
                      autoComplete="current-email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <FormHelperText
                        error
                        style={{ textAlign: "left", fontSize: "13px" }}
                      >
                        {errors.email}
                      </FormHelperText>
                    )}
                  </Box>
                  <Box py={3}>
                    <FormControl sx={{ width: "100%" }} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        error={!!errors.password && touched.password}
                        name="password"
                        autoComplete="current-password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    {errors.password && touched.password && (
                      <FormHelperText
                        error
                        style={{ textAlign: "left", fontSize: "13px" }}
                      >
                        {errors.password}
                      </FormHelperText>
                    )}
                    <Box textAlign="start" mt={1}>
                      <span
                        onClick={() => setOpenEdit(true)}
                        className="cursor-pointer"
                      >
                        Forgot Password?
                      </span>
                    </Box>
                  </Box>

                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    color="warning"
                    sx={{ textTransform: "capitalize", mt: 2, color: "#fff" }}
                  >
                    {isSubmitting ? <BeatLoader color="#ff0000" /> : "Login"}
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Box>

      <ForgotPassword openEdit={openEdit} setOpenEdit={setOpenEdit} />
    </Box>
  );
};

export default LoginScreen;

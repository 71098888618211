import React, { useState } from "react";
import TransitionsModal from "../../components/Modal";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import { postApi } from "../../config/configAxios";

const ForgotPassword = ({ openEdit, setOpenEdit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    email: "",
    otp: "",
    password: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      if (!success) {
        const res = await postApi(
          `${process.env.REACT_APP_API_BASE_URL}forgot-password`,
          { email: data.email }
        );
        setData((prev) => {
          return { ...prev, email: "" };
        });
        toast.success(res?.data?.message);
        setSuccess(res?.data?.status);
      } else {
        const res = await postApi(
          `${process.env.REACT_APP_API_BASE_URL}update-password/${data.otp}`,
          { password: data.password }
        );
        setData({
          email: "",
          otp: "",
          password: "",
        });
        toast.success(res?.data?.message);
        setOpenEdit(false);
        setSuccess(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <TransitionsModal
      title={"Forgot password"}
      open={openEdit}
      setOpen={setOpenEdit}
    >
      <form onSubmit={handleSubmit}>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-3">
          {!success ? (
            <div className="md:col-span-2">
              <label htmlFor="link" className="font-semibold text-sm">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={data.email}
                onChange={(e) => {
                  setData((prev) => ({ ...prev, email: e.target.value }));
                }}
                placeholder="type email"
                className="form-control"
                required
              />
            </div>
          ) : (
            <>
              <div className="md:col-span-2">
                <label htmlFor="otp" className="font-semibold text-sm">
                  OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  name="otp"
                  value={data.otp}
                  onChange={(e) => {
                    setData((prev) => ({ ...prev, otp: e.target.value }));
                  }}
                  maxLength={6}
                  minLength={6}
                  placeholder="type otp"
                  className="form-control"
                  required
                />
              </div>
              <div className="md:col-span-2">
                <label htmlFor="password" className="font-semibold text-sm">
                  New password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={data.password}
                  onChange={(e) => {
                    setData((prev) => ({ ...prev, password: e.target.value }));
                  }}
                  minLength={6}
                  placeholder="type new password"
                  className="form-control"
                  required
                />
              </div>
            </>
          )}
          <div className="md:col-span-2">
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn-green w-full mt-4"
              data-ripple-light="true"
            >
              {isSubmitting ? <BeatLoader color="#fff" /> : "Send OTP"}
            </button>
          </div>
        </div>
      </form>
    </TransitionsModal>
  );
};

export default ForgotPassword;
